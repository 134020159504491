import { TDateSections } from './types';

export const DEFAULT_DATE_STATE: TDateSections = {
  day: null,
  month: null,
  year: null,
};

export const COUNT_DAY_DIGITS = 2;
export const COUNT_MONTH_DIGITS = 2;
export const COUNT_YEAR_DIGITS = 4;
