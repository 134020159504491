import { computed, Ref } from 'vue';

import {
  COUNT_DAY_DIGITS,
  COUNT_MONTH_DIGITS,
  COUNT_YEAR_DIGITS,
} from '@/ui/DatePicker/domain/constants';
import { TDateSections } from '@/ui/DatePicker/domain/types';

type TUseView = {
  isInputActive: Ref<boolean>,
  state: Ref<TDateSections>,
};

export const useView = ({
  isInputActive,
  state,
}: TUseView) => {
  const dayPlaceholderCount = computed(
    () => (state.value.day?.length
      ? COUNT_DAY_DIGITS - state.value.day.length
      : COUNT_DAY_DIGITS),
  );

  const monthPlaceholderCount = computed(
    () => (state.value.month?.length
      ? COUNT_MONTH_DIGITS - state.value.month.length
      : COUNT_MONTH_DIGITS),
  );

  const yearPlaceholderCount = computed(
    () => (state.value.year?.length
      ? COUNT_YEAR_DIGITS - state.value.year.length
      : COUNT_YEAR_DIGITS),
  );

  const isDateSelected = computed(
    () => !!(state.value.day && state.value.month && state.value.year),
  );

  const isDotDividerShown = computed(() => isDateSelected.value || isInputActive.value);

  return {
    dayPlaceholderCount,
    monthPlaceholderCount,
    yearPlaceholderCount,
    isDotDividerShown,
  };
};
