// eslint-disable-next-line no-restricted-imports
import dayjs from '@/utils/dateUtils/dayjsConfig';
import { TDateAppFormat } from '@/types';

/**
 * Проверка валидности даты.
 *
 * При не строгой проверке (strict=false):
 * 1. Для даты '2024-02-32' вернется true, так как сначала дата преобразуется в '2024-03-03'.
 * 2. Для корректной даты, вернется true, если формат проверяемой даты частично соответствует формату даты переданному в качестве аргумента (YYYY-MM-DDTHH:mm:ssZ <-> YYYY-MM-DD)
 *
 * Строгий парсинг требует, чтобы формат даты и входные данные совпадали, включая знаки-делители.
 * */
const isValidDate = (
  date: string | number | TDateAppFormat,
  format: string,
  strict: boolean = false,
) => dayjs(date, format, strict).isValid();

export default isValidDate;
