<template>
  <div
    ref="datePickerInputRef"
    :class="[
      'date-picker-input',
      `date-picker-input_${size}`,
    ]"
    :tabindex="disabled ? undefined : 0"
    @focus="handleInputFocus"
    @blur="$emit('blur')"
  >
    <div
      :class="[
        'date-picker-input__sections',
        `date-picker-input__sections_${size}`,
      ]"
    >
      <DatePickerInputSection
        :value="state.day"
        :isSelected="selectedSection === EDatePickerInputSection.day"
        :isInputActive="isInputActive"
        :placeholderCount="dayPlaceholderCount"
        :size="size"
        @select="handleSectionSelect(EDatePickerInputSection.day)"
      />

      <span
        v-if="isDotDividerShown"
        class="date-picker-input__divider"
        :class="{ 'date-picker-input__divider_black': !!state.month }"
      >.</span>

      <DatePickerInputSection
        :value="state.month"
        :isSelected="selectedSection === EDatePickerInputSection.month"
        :isInputActive="isInputActive"
        :placeholderCount="monthPlaceholderCount"
        :size="size"
        @select="handleSectionSelect(EDatePickerInputSection.month)"
      />

      <span
        v-if="isDotDividerShown"
        class="date-picker-input__divider"
        :class="{ 'date-picker-input__divider_black': !!state.year }"
      >.</span>

      <DatePickerInputSection
        :value="state.year"
        :isSelected="selectedSection === EDatePickerInputSection.year"
        :isInputActive="isInputActive"
        :placeholderCount="yearPlaceholderCount"
        :size="size"
        @select="handleSectionSelect(EDatePickerInputSection.year)"
      />
    </div>

    <CalendarSvg class="date-picker__icon" />
  </div>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
  ref,
  toRefs,
} from 'vue';

import CalendarSvg from '@/assets/svg/16x16/calendar.svg';
import { EDatePickerInputSection, ESize } from '@/ui/types/constants';

import { useView } from './application/useView';
import DatePickerInputSection from './components/DatePickerInputSection/index.vue';
import { TDateSections } from '../../domain/types';

export default defineComponent({
  name: 'DatePickerInput',
  components: {
    CalendarSvg,
    DatePickerInputSection,
  },
  props: {
    state: {
      type: Object as PropType<TDateSections>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
    isInputActive: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['focus', 'blur'],
  setup(props, { expose, emit }) {
    const {
      state,
      isInputActive,
      disabled,
    } = toRefs(props);

    const datePickerInputRef = ref<HTMLDivElement | null>(null);

    const selectedSection = ref<EDatePickerInputSection | null>(null);

    const {
      dayPlaceholderCount,
      monthPlaceholderCount,
      yearPlaceholderCount,
      isDotDividerShown,
    } = useView({
      isInputActive,
      state,
    });

    const handleSectionSelect = (section: EDatePickerInputSection) => {
      selectedSection.value = section;
    };

    const resetSelectedSection = () => {
      selectedSection.value = null;
    };

    const handleInputFocus = () => {
      if (disabled.value) return;

      if (!selectedSection.value) {
        selectedSection.value = EDatePickerInputSection.day;
      }

      emit('focus');
    };

    const blur = () => datePickerInputRef.value?.blur();

    expose({
      blur,
      resetSelectedSection,
    });

    return {
      dayPlaceholderCount,
      monthPlaceholderCount,
      yearPlaceholderCount,
      datePickerInputRef,
      selectedSection,
      isDotDividerShown,
      EDatePickerInputSection,

      handleInputFocus,
      handleSectionSelect,
      resetSelectedSection,
      blur,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
