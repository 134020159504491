import { ESearchStateSection } from '@/stores/search/constants';

import { TDataForResetPagination } from './types';

/**
 * Хранит условную связь поискового запроса (секцию в сторе) с соответствующей пагинацией (данные для сброса) для таблиц(ы).
 * Это необходимо для выполнения требований по сбросу пагинации таблиц(ы) с поиском,
 * при переходе между разделами и при наличии соответствующего данной таблице поискового запроса.
 */
export const DATA_FOR_RESET_PAGINATION: TDataForResetPagination[] = [
  {
    searchSection: ESearchStateSection.directions,
    paginationResetPayloads: [{ tableName: 'directionsTable' }],
  },
  {
    searchSection: ESearchStateSection.operationalDocuments,
    paginationResetPayloads: [{ tableName: 'operationalDocumentsListTable' }],
  },
  {
    searchSection: ESearchStateSection.partnershipsContracts,
    paginationResetPayloads: [{ tableName: 'partnershipContractsTable' }],
  },
  {
    searchSection: ESearchStateSection.partnerships,
    paginationResetPayloads: [
      { tableName: 'partnershipsTable' },
      { tableName: 'partnershipInvitationsTable' },
    ],
  },
  {
    searchSection: ESearchStateSection.partnershipResources,
    paginationResetPayloads: [
      { tableName: 'partnershipResourcesVehicles' },
      { tableName: 'partnershipResourcesDrivers' },
    ],
  },
  {
    searchSection: ESearchStateSection.executorsLookupsFeed,
    paginationResetPayloads: [
      { tableName: 'executorsLookupFeedTable' },
    ],
  },
  {
    searchSection: ESearchStateSection.selfBillingListView,
    paginationResetPayloads: [{ tableName: 'selfBillingListView' }],
  },
];
