import { httpMethods } from '@/api';
import { sendApiRequest } from '@/api/sendApiRequest';
import {
  TContractorsResponse,
  TContractorAddress,
  TContractorsImportResponse,
  TContractorAddressPayload,
  TDigitalQueueActionRequest,
  TDigitalQueueDetails,
  TDigitalQueueCreateSchedulesRequest,
  TDigitalQueueEntitiesForGateRequest,
  TDigitalQueueGateSchedules,
  TSchedule,
  TDigitalQueueGateBookings,
  TDigitalQueueCreateBookingsRequest,
  TBooking,
  TDigitalQueueBookingResponse,
  TDigitalQueueCopyScheduleRequest,
  TContractorAddressEmails,
  TDigitalQueueEditScheduleRequest,
  TContractorByEntryUidResponse,
  TSetCheckmarkCreateRequest,
  TDriverResponse,
  TContractorIncidentResponse,
  TChangeBookingWorkflowStateRequest,
  TBookingWorkflowStateResponse,
  TAvailableBookingWorkflowResponse,
  TBookingWorkflowStatesHistoryRequest,
  TDigitalQueueAddGatesPayload,
  TDigitalQueueBookingUpdatePayload,
  TDigitalQueueGates,
  TAvailableTimeSlotsResponse,
  TContractorsRequest,
  TBookedTimeSlot,
  TDigitalQueueReferenceNumbersPayload,
  TDigitalQueueFreightRequestData,
  TDigitalQueueReferenceNumberResponse,
  TDigitalQueueBookingWithReferenceNumberPayload,
  TBookingAuditEventResponse,
  TExternalDigitalQueuesResponse,
  TExternalDigitalQueueManifestResponse,
  TDigitalQueueShipmentData,
  TDigitalQueueBookingWithShipmentPayload,
} from '@/contexts/contractorsContext/domain/types';
import {
  TCustomizationFields,
  TFormSchemaForChangeWorkflowState,
  TPagination,
  TResponseWithId,
} from '@/types';
import tt from '@/i18n/utils/translateText';
import { TContractorInfo } from '@/components/layouts/domain/types';
import { TCustomTableSort } from '@/components/CustomTableLayout/types';
import { TRunInfo } from '@/contexts/runExecutionContext/types';
import { TShipmentBookingPayload } from '@/contexts/orderingContext/views/ShipmentsInfoView/domain/types';
import { TDigitalQueue } from '@/contexts/digitalQueuesContext/domain/types';

import { ABORT_SIGNAL_NAME } from './constants';

export const fetchContractors = ({
  pageItems,
  currentPage,
  category,
  searchQuery,
} = {
  pageItems: null,
  currentPage: null,
  category: null,
  searchQuery: null,
} as TContractorsRequest,
) => sendApiRequest<TContractorsResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/contractors',
  params: {
    items: pageItems,
    page: currentPage,
    category,
    searchQuery,
  },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const fetchContractor = ({ entryUid }: { entryUid: string }) => sendApiRequest<TContractorByEntryUidResponse>({
  method: httpMethods.GET,
  endpoint: `/contractors/${entryUid}`,
  withoutAuth: true,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getContractorAddressInfo = (contractorId: string, addressId: string) => sendApiRequest<any>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/contractors/${contractorId}/addresses/${addressId}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const sendEmailWithCredentials = ({
  contractorId,
  addressId,
  notificationEmails,
}: {
  contractorId: string,
  addressId: string,
  notificationEmails: TContractorAddressEmails,
}) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/contractors/${contractorId}/addresses/${addressId}/notification_emails/send_credentials`,
  payload: { notificationEmails },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const unsubscribeUser = ({ entryUid, email }: { entryUid: string, email: string }) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/as_contractor/unsubscribe',
  params: {
    entryUid,
    email,
  },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export function fetchRuns(
  { pageItems, currentPage }: TPagination,
  { sort, sortBy }: TCustomTableSort,
  filters: any,
  withIncidentsOnTop = false,
) {
  return sendApiRequest({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/as_contractor/runs',
    params: {
      items: pageItems,
      page: currentPage,
      sort,
      sortBy,
      withIncidentsOnTop,
      ...filters,
    },
    requestOptions: {
      showError: true,
      message: tt('runExecution.shared.errors.fetchRuns'),
    },
  });
}

export function getDriversByNameQuery(name: string) {
  return sendApiRequest<TDriverResponse[]>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/autocomplete/as_contractor/run_drivers',
    params: { name },
  });
}

export const importContractors = ({ file }: { file: any }) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/contractors/imports',
  params: { file },
});

export const getImportReport = ({ reportId }: { reportId: any }) => sendApiRequest<TContractorsImportResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/contractors/imports/${reportId}`,
});

export const removeContractor = (contractorId: string) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/contractors/${contractorId}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const addAddressToContractor = ({
  contractorId,
  address,
  notificationEmails,
  isExport,
  externalDigitalQueueId,
  externalDigitalQueueSettingsAttributes,
}: {
  contractorId: string,
  address: any,
  notificationEmails?: TContractorAddressEmails,
  isExport: boolean,
  externalDigitalQueueId?: string,
  externalDigitalQueueSettingsAttributes?: TCustomizationFields,
}) => {
  const {
    id: addressId,
    description,
    ...addressAttributes
  } = address;

  const payload: TContractorAddressPayload = {
    description,
    notificationEmails,
    export: isExport,
    externalDigitalQueueId,
    externalDigitalQueueSettingsAttributes,
  };

  if (addressId) {
    payload.addressId = addressId;
  } else {
    payload.addressAttributes = addressAttributes;
  }

  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/contractors/${contractorId}/addresses`,
    payload,
  });
};

export const editAddressContacts = ({
  contractorId,
  addressId,
  contacts,
}: {
  contractorId: string,
  addressId: string,
  contacts: any,
}) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/contractors/${contractorId}/addresses/${addressId}/contacts`,
  payload: { contacts },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const editContactsEmails = ({
  contractorId,
  addressId,
  notificationEmails,
}: {
  contractorId: string,
  addressId: string | null,
  notificationEmails: TContractorAddressEmails,
}) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/contractors/${contractorId}/addresses/${addressId}/notification_emails`,
  payload: { notificationEmails },
});

export const getContractorAddresses = (contractorId: string) => sendApiRequest<TContractorAddress[]>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/contractors/${contractorId}/addresses`,
});

export function fetchRunInfo(uid: string) {
  return sendApiRequest<TRunInfo>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/as_contractor/runs/${uid}`,
  });
}

export const setCheckmark = (uid: string, payload: TSetCheckmarkCreateRequest) => sendApiRequest<TRunInfo>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/as_contractor/runs/${uid}/checkmarks`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getDigitalQueues = () => sendApiRequest<TDigitalQueue[]>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/digital_queues',
});

export const addDigitalQueue = (payload: TDigitalQueueActionRequest) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/digital_queues',
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const deleteDigitalQueue = (digitalQueueId: string) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getDigitalQueueDetails = (digitalQueueId: string) => sendApiRequest<TDigitalQueueDetails>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const updateDigitalQueue = (
  digitalQueueId: string,
  payload: TDigitalQueueActionRequest) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}`,
  payload,
  requestOptions: { serverErrorFirst: true },
});

export const getDigitalQueueGates = (digitalQueueId: string) => sendApiRequest<TDigitalQueueGates[]>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/gates`,
});

export const addDigitalQueueSchedules = (
  digitalQueueId: string,
  payload: TDigitalQueueCreateSchedulesRequest,
) => sendApiRequest<TDigitalQueueGateSchedules>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/schedules`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const addDigitalQueueGates = (digitalQueueId: string, payload: TDigitalQueueAddGatesPayload) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/gates`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getDigitalQueueSchedulesForGate = (
  digitalQueueId: string,
  gateId: string,
  payload?: TDigitalQueueEntitiesForGateRequest) => sendApiRequest<TDigitalQueueGateSchedules>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/gates/${gateId}/schedules`,
  params: payload,
});

export const getSchedule = (
  digitalQueueId: string,
  scheduleId: string) => sendApiRequest<TSchedule>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/schedules/${scheduleId}`,
});

export const deleteSchedule = (digitalQueueId: string, scheduleId: string) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/schedules/${scheduleId}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const editSchedule = (
  digitalQueueId: string,
  scheduleId: string,
  payload: TDigitalQueueEditScheduleRequest) => sendApiRequest<TDigitalQueueGateSchedules>({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/schedules/${scheduleId}`,
  params: payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getDigitalQueueBookingsForGate = (
  digitalQueueId: string,
  gateId: string,
  payload: TDigitalQueueEntitiesForGateRequest) => sendApiRequest<TDigitalQueueGateBookings>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/gates/${gateId}/bookings`,
  params: payload,
});

export const addDigitalQueueBookings = (
  digitalQueueId: string,
  payload: TDigitalQueueCreateBookingsRequest,
) => sendApiRequest<TBooking[]>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings`,
  payload,
});

export const annulBooking = (digitalQueueId: string, bookingId: string) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `:tenant_id/digital_queues/${digitalQueueId}/bookings/${bookingId}/annulation`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getDigitalQueueReferenceNumbers = (
  payload: TDigitalQueueReferenceNumbersPayload,
) => sendApiRequest<TDigitalQueueReferenceNumberResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/autocomplete/digital_queues/freight_requests',
  params: payload,
});

export const getDigitalQueueShipmentReferenceNumbers = (
  payload: TDigitalQueueReferenceNumbersPayload,
) => sendApiRequest<TDigitalQueueReferenceNumberResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/autocomplete/digital_queues/shipments',
  params: payload,
});

export const getTransitPointsData = (
  digitalQueueId: string,
  gateId: string,
  freightRequestIid: number,
) => sendApiRequest<TDigitalQueueFreightRequestData>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/gates/${gateId}/freight_requests/${freightRequestIid}`,
});

export const getShipmentData = (
  digitalQueueId: string,
  gateId: string,
  shipmentIid: number,
) => sendApiRequest<TDigitalQueueShipmentData>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/gates/${gateId}/shipments/${shipmentIid}`,
});

export const getBooking = (digitalQueueId: string, bookingId: string) => sendApiRequest<TDigitalQueueBookingResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/${bookingId}`,
});

export const getBookingAvailableTimeSlots = (
  digitalQueueId: string,
  gateId: string,
  exactDate: string,
) => sendApiRequest<TAvailableTimeSlotsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/gates/${gateId}/available_time_slots`,
  params: { exactDate },
});

export const updateBookingTimeSlot = (
  {
    digitalQueueId,
    bookingId,
    payload,
  }: {
    digitalQueueId: string,
    bookingId: string,
    payload: TBookedTimeSlot,
  }) => sendApiRequest<TDigitalQueueBookingResponse>({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/${bookingId}/time_slot`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const updateBooking = (
  digitalQueueId: string,
  bookingId: string,
  payload: TDigitalQueueBookingUpdatePayload,
) => sendApiRequest<TDigitalQueueBookingResponse>({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/${bookingId}`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const updateBookingComment = (
  digitalQueueId: string,
  bookingId: string,
  payload: Pick<TDigitalQueueBookingUpdatePayload, 'comment'>,
) => sendApiRequest<Pick<TDigitalQueueBookingResponse, 'comment'>>({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/${bookingId}/comment`,
  payload,
});

export const reassignBooking = ({
  digitalQueueId,
  bookingId,
  transitPointId,
  runUid,
  executionRequestUid,
  comment,
}: TDigitalQueueBookingWithReferenceNumberPayload
& { digitalQueueId: string, bookingId: string },
) => sendApiRequest<TResponseWithId>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/${bookingId}/reassign`,
  payload: {
    transitPointId,
    runUid,
    executionRequestUid,
    comment,
  },
});

export const reassignBookingForShipment = ({
  digitalQueueId,
  bookingId,
  shipmentUid,
}: TDigitalQueueBookingWithShipmentPayload) => sendApiRequest<TResponseWithId>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/${bookingId}/reassign`,
  payload: { shipmentUid },
});

/** Заполнение ресурсов самовывоза в ЦО */
export const fillShipmentBookingResources = (
  digitalQueueId: string,
  shipmentId: string,
  payload: TShipmentBookingPayload,
) => sendApiRequest<TResponseWithId>({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/shipment_bookings/${shipmentId}`,
  payload,
});

/** Редактирование уже заполненных ресурсов самовывоза в ЦО */
export const editShipmentBookingResources = (
  bookingId: string,
  shipmentId: string,
  payload: TShipmentBookingPayload,
) => sendApiRequest<TResponseWithId>({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/shipments/${shipmentId}/bookings/${bookingId}/driver`,
  payload,
});

export const bookTimeSlotForRunBooking = (
  digitalQueueId: string,
  payload: TBookedTimeSlot,
) => sendApiRequest<TResponseWithId>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/run_booking`,
  payload,
});

export const rebookTimeSlotForRunBooking = (
  digitalQueueId: string,
  payload: TBookedTimeSlot,
) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/run_booking`,
  payload,
});

export const rebookTimeSlotForBookingShipment = (
  shipmentId: string,
  bookingId: string,
  payload: TBookedTimeSlot,
) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/shipments/${shipmentId}/bookings/${bookingId}/time_slot`,
  payload,
});

export const reserveTimeSlotForExecutionRequest = (
  digitalQueueId: string,
  payload: TBookedTimeSlot,
) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/execution_request_reservation`,
  payload,
});

export const reserveTimeSlotForShipment = (
  digitalQueueId: string,
  payload: TBookedTimeSlot,
) => sendApiRequest<TResponseWithId>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/shipment_bookings`,
  payload,
});

export const overbookTimeSlotForExecutionRequestReservation = (
  digitalQueueId: string,
  payload: TBookedTimeSlot,
) => sendApiRequest<TResponseWithId>({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/execution_request_reservation`,
  payload,
});

export const copyDigitalQueueSchedule = (
  digitalQueueId: string,
  scheduleId: string,
  payload: TDigitalQueueCopyScheduleRequest,
) => sendApiRequest<TDigitalQueueGateSchedules>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/schedules/${scheduleId}/duplicates`,
  payload,
});

export const updateContractorExternalNumber = (
  contractorId: string,
  externalNumber: string,
) => sendApiRequest<TResponseWithId>({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/contractors/${contractorId}/external_number`,
  payload: { externalNumber },
});

export const updateContractorInn = (
  contractorId: string,
  inn: string,
) => sendApiRequest<TResponseWithId>({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/contractors/${contractorId}/inn`,
  payload: { inn },
});

export const updateContractorKpp = (
  contractorId: string,
  kpp: string,
) => sendApiRequest<TResponseWithId>({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/contractors/${contractorId}/kpp`,
  payload: { kpp },
});

export const updateContractorName = (
  contractorId: string,
  name: string,
) => sendApiRequest<TResponseWithId>({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/contractors/${contractorId}/name`,
  payload: { name },
});

export const updateContractorOgrn = (
  contractorId: string,
  ogrn: string,
) => sendApiRequest<TResponseWithId>({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/contractors/${contractorId}/ogrn`,
  payload: { ogrn },
});

export const updateContractorLegalAddress = (
  contractorId: string,
  legalAddress: string,
) => sendApiRequest<TResponseWithId>({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/contractors/${contractorId}/legal_address`,
  payload: { legalAddress },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const updateContractorCategories = (
  contractorId: string,
  categories: string[],
) => sendApiRequest<TResponseWithId>({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/contractors/${contractorId}/categories`,
  payload: { categories },
});

export const getRunIncidents = (pagination: TPagination) => sendApiRequest<TContractorIncidentResponse[]>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/as_contractor/runs/incidents',
  params: {
    items: pagination.pageItems,
    page: pagination.currentPage,
  },
});

export const getContractorInfo = () => sendApiRequest<TContractorInfo>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/as_contractor/info',
});

export function changeBookingWorkflowState(
  digitalQueueId: string,
  bookingId: string,
  payload: TChangeBookingWorkflowStateRequest) {
  return sendApiRequest<TBookingWorkflowStateResponse>({
    method: httpMethods.PUT,
    endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/${bookingId}/workflow/state`,
    payload,
  });
}

export function getAvailableBookingWorkflow(
  digitalQueueId: string,
  bookingId: string) {
  return sendApiRequest<TAvailableBookingWorkflowResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/${bookingId}/workflow`,
    signalName: ABORT_SIGNAL_NAME.contractorsContextAvailableBookingWorkflow,
  });
}

export function getFormSchemaForChangeBookingWorkflowState(
  digitalQueueId: string,
  bookingId: string,
  screen: string) {
  return sendApiRequest<TFormSchemaForChangeWorkflowState>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/${bookingId}/workflow/screens/${screen}`,
  });
}

export function rollbackBookingWorkflowState(
  digitalQueueId: string,
  bookingId: string) {
  return sendApiRequest<TBookingWorkflowStateResponse>({
    method: httpMethods.DELETE,
    endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/${bookingId}/workflow/state`,
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function getBookingWorkflowStatesHistory(
  digitalQueueId: string,
  bookingId: string,
) {
  return sendApiRequest<TBookingWorkflowStatesHistoryRequest>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/${bookingId}/workflow/states`,
  });
}

export const getBookingAuditLogs = (
  digitalQueueId: string,
  bookingId: string,
) => sendApiRequest<TBookingAuditEventResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/digital_queues/${digitalQueueId}/bookings/${bookingId}/audit_events`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getExternalDigitalQueues = () => sendApiRequest<TExternalDigitalQueuesResponse>({
  method: httpMethods.GET,
  endpoint: ':tenant_id/external_digital_queues',
});

export const getExternalDigitalQueuesManifests = () => sendApiRequest<TExternalDigitalQueueManifestResponse>({
  method: httpMethods.GET,
  endpoint: ':tenant_id/external_digital_queue_manifest',
});
