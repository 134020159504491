import { TDateAppFormat } from '@/types';
import { METHOD_BY_PART } from '@/utils/dateUtils/domains/constants';
import isDayjsGuard from '@/utils/dateUtils/domains/guards';
import isValidJSDate from '@/utils/dateUtils/predicates/isValidJSDate';

import isNumber from '../../isNumber';
import isString from '../../isString';

const getFromDate = (part: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year') => (date: TDateAppFormat | number | string): number => {
  if (isDayjsGuard(date)) {
    return date[METHOD_BY_PART[part].appFormat]();
  }

  if (isNumber(date) || isString(date)) {
    const jsDate = new Date(date);

    return isValidJSDate(jsDate) ? jsDate[METHOD_BY_PART[part].jsDate]() : 0;
  }

  return 0;
};

export default getFromDate;
