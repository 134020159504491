<template>
  <Drawer
    class="custom-drawer"
    :class="{
      'custom-drawer_flat': flat,
      'custom-drawer_with-footer': isFooterVisible,
    }"
    :width="drawerWidth"
    :closable="false"
    :visible="visible"
  >
    <div v-if="tabs.length > 0" class="custom-drawer__tabs-container">
      <Tabs
        :activeKey="activeTab"
        type="card"
        tabPosition="left"
        size="small"
        :tabBarGutter="0"
        @change="$emit('changeTab', $event)"
      >
        <TabPane v-for="tab in tabs" :key="tab.key">
          <template #tab>
            <component :is="tab.icon" />
          </template>
        </TabPane>
      </Tabs>
    </div>

    <div class="custom-drawer__main">
      <div class="custom-drawer__header">
        <div class="custom-drawer__title">
          <slot name="title">
            {{ title }}
          </slot>
        </div>
        <slot name="icon">
          <button class="ant-drawer-close" @click="$emit('close')">
            <CloseSvg />
          </button>
        </slot>
      </div>
      <div class="custom-drawer__content">
        <slot />
      </div>
    </div>

    <footer
      v-if="isFooterVisible"
      class="custom-drawer__footer"
      :class="{ 'custom-drawer__footer': footerClass }"
    >
      <slot name="footer" />
    </footer>
  </Drawer>
</template>

<script lang="ts">
import {
  Drawer,
  Tabs,
  TabPane,
} from 'ant-design-vue';
import {
  defineComponent,
  onMounted,
  PropType,
  Ref,
  ref,
  toRefs,
} from 'vue';

import CloseSvg from '@/assets/svg/16x16/close.svg';
import InfoSvg from '@/assets/svg/16x16/info.svg';
import TenderSvg from '@/assets/svg/16x16/tender.svg';
import CommitmentSvg from '@/assets/svg/16x16/commitment.svg';
import { useHasSlot } from '@/composables/useHasSlot';
import { isNumber } from '@/utils';

type TDrawerTab = {
  key: string,
  icon: string,
};

export default defineComponent({
  name: 'CustomDrawer',
  components: {
    Drawer,
    Tabs,
    TabPane,
    CloseSvg,
    InfoSvg,
    TenderSvg,
    CommitmentSvg,
  },
  props: {
    footerClass: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Number, null] as PropType<string | number | null>,
      default: null,
    },
    percentFromScreenWidth: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    activeTab: {
      type: String,
      default: '',
    },
    tabs: {
      type: Array as PropType<TDrawerTab[]>,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'changeTab'],
  setup(props, { slots }) {
    const {
      width,
      percentFromScreenWidth,
    } = toRefs(props);

    const isFooterVisible = useHasSlot(slots, 'footer');

    const drawerWidth: Ref<number | string | undefined> = ref();

    const setDrawerWidth = () => {
      let calculatedWith: string | number | null;
      // если передан процент от ширины экрана, сначала пробуем растянуть дровер на соответствующую этому проценту ширину
      if (percentFromScreenWidth.value) {
        // если при этом передана ширина для дровера, используем её в качестве минимальной (актуально для узких экранов).
        // иначе вместо неё для расчетов берем 0, и дровер просто станет занимать соответствующий процент от ширины экрана
        const minDrawerWidth = isNumber(width.value) ? width.value : 0;

        const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        // процент от ширины экрана в пиксельном эквиваленте
        const viewportWidthFraction = Math.round(viewportWidth * percentFromScreenWidth.value * 0.01);

        calculatedWith = Math.max(minDrawerWidth, viewportWidthFraction);
      } else {
        calculatedWith = width.value;
      }
      // если в calculatedWith оказался 0, undefined или null, приводим его к '', чтобы антовский дровер использовал ширину из styles.css
      drawerWidth.value = calculatedWith || '';
    };

    onMounted(setDrawerWidth);

    return {
      isFooterVisible,
      drawerWidth,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
