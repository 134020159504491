import { InjectionKey } from 'vue';

import { TRadioGroupContext } from './types';

export enum ESize {
  small = 'small',
  large = 'large',
  medium = 'medium',
}

export enum EDeclension {
  masculine = 'masculine',
  feminine = 'feminine',
  neuter = 'neuter',
  plural = 'plural',
}

export enum EButtonType {
  primary = 'primary',
  secondary = 'secondary',
  danger = 'danger',
  ghostPrimary = 'ghost-primary',
  ghostSecondary = 'ghost-secondary',
  link = 'link',
}

export enum EActionSheetType {
  default = 'default',
  negative = 'negative',
  parentItem = 'parentItem',
}

export enum EHtmlType {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

export enum EInputTypes {
  text = 'text',
  email = 'email',
  search = 'search',
  tel = 'tel',
  number = 'number',
  password = 'password',
}

export enum EInputModes {
  none = 'none',
  text = 'text',
  numeric = 'numeric',
  decimal = 'decimal',
  tel = 'tel',
  email = 'email',
  search = 'search',
  url = 'url',
}

export enum ERadioButtonOptionType {
  default = 'default',
  button = 'button',
}

export enum EPopoverPlacement {
  top = 'top',
  topLeft = 'topLeft',
  topRight = 'topRight',
  left = 'left',
  leftTop = 'leftTop',
  leftBottom = 'leftBottom',
  right = 'right',
  rightTop = 'rightTop',
  rightBottom = 'rightBottom',
  bottom = 'bottom',
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
}

export enum EBadgeColor {
  gray = 'gray',
  red = 'red',
  volcano = 'volcano',
  orange = 'orange',
  gold = 'gold',
  lime = 'lime',
  green = 'green',
  cyan = 'cyan',
  blue = 'blue',
  geekblue = 'geekblue',
  purple = 'purple',
  magenta = 'magenta',
  white = 'white',
}

export const VIEWPORT_PADDING = 40;
export const DROPDOWN_MARGIN = 2;
// TODO: разобраться почему 51, а не 47 (30px search + 16px padding + 1px border)
// Скорее всего правильно 47, и где-то добавились лишние 2px отступа от targetElement
export const DROPDOWN_LIST_HEADER = 51;
export const DROPDOWN_PADDING = 6;
export const DROPDOWN_PADDING_WITH_MARGIN = DROPDOWN_PADDING + DROPDOWN_MARGIN;
export const MAX_VISIBLE_DROPDOWN_ITEM = 10;
export const MIN_ITEM_HEIGHT = 32;
// 1 elem + padding up and down 4px;
export const MIN_DROPDOWN_HEIGHT = MIN_ITEM_HEIGHT + 8;

export const RadioGroupContextKey: InjectionKey<TRadioGroupContext> = Symbol('radioGroupContext');

export const DEFAULT_MIN_DATE = '1900-01-01';
export const DEFAULT_MAX_DATE = '2099-12-31';

export const COUNT_MONTHS_IN_YEAR = 12;

export enum EDatePickerInputSection {
  day = 'day',
  month = 'month',
  year = 'year',
}

export enum EMonth {
  january = 1,
  february,
  march,
  april,
  may,
  june,
  july,
  august,
  september,
  october,
  november,
  december,
}

export enum EDatePickerValueFormat {
  /** 13.08.2021 */
  fullDate = 'DD.MM.YYYY',
  /** 13.08.21 */
  fullDateWithShortYears = 'DD.MM.YY',
  /** 13.08.2021, 15:19 */
  fullDateWithHoursMinutes = 'DD.MM.YYYY, HH:mm',
  /** 18 февраля 2024 */
  dayMonthYear = 'DD MMMM YYYY',
  /** 2022-05-20 */
  UTCformat = 'YYYY-MM-DD',
  /** 2022-05-20 15:19 */
  UTCWithTimeFormat = 'YYYY-MM-DD HH:mm',
  /** 2023-07-05T10:50:00 */
  ISOLocal = 'YYYY-MM-DDTHH:mm:ss',
  /** 2021/08/13 15:19 */
  fullDateWithHoursMinutesSlashed = 'YYYY/MM/DD, HH:mm',
  /** 2024-08-14T14:00:00+02:00 */
  ISODateTimeWithZone = 'YYYY-MM-DDTHH:mm:ssZ',
}

export enum EEditorNode {
  bulletList = 'bulletList',
  orderedList = 'orderedList',
}

export enum EEditorMark {
  bold = 'bold',
  italic = 'italic',
  strike = 'strike',
}
