<template>
  <ControlWrapper
    ref="wrapperRef"
    class="date-picker"
    :size="size"
    :disabled="disabled"
    :isInvalid="isInvalid"
  >
    <DatePickerInput
      ref="datePickerInputRef"
      :disabled="disabled"
      :state="selectedDateState"
      :size="size"
      :isInputActive="isDropdownVisible"
      @focus="handleDatePickerInputFocus"
      @blur="handleDatePickerInputBlur"
    />

    <DropdownList
      ref="dropdownRef"
      className="date-picker__dropdown"
      :targetElement="wrapperHtmlRef"
      :visible="isDropdownVisible"
      withoutOptions
      :dropdownMatchSelectWidth="256"
      isFixedOnBottomLeft
      @blur="handleDropdownBlur"
    >
      <div class="date-picker__dropdown-header">
        <Select
          v-model:value="monthSelectValue"
          class="date-picker__select date-picker__select-month"
          :hasBorder="false"
          :options="monthsOptions"
          :size="ESize.small"
          :dropdownMatchSelectWidth="false"
          readonly
          @focus="handleSelectFocus"
          @blur="handleSelectBlur"
          @select="handleMonthSelect"
        >
          <template #addonBefore>
            <SelectorVerticalSvg />
          </template>
        </Select>

        <Select
          v-model:value="yearSelectValue"
          class="date-picker__select date-picker__select-year"
          :hasBorder="false"
          :options="yearsOptions"
          :size="ESize.small"
          :dropdownMatchSelectWidth="false"
          readonly
          @focus="handleSelectFocus"
          @blur="handleSelectBlur"
          @select="handleYearSelect"
        >
          <template #addonBefore>
            <SelectorVerticalSvg />
          </template>
        </Select>
      </div>

      <div class="date-picker__divider" />

      <DatePickerMonthFeed
        ref="datePickerMonthFeedRef"
        class="date-picker__month-feed"
        :selectedMonth="monthSelectValue"
        :selectedYear="yearSelectValue"
        :selectedDate="selectedDateValue"
        :minDate="minDate"
        :maxDate="maxDate"
        @select="handleDaySelect"
        @changeSelectedMonth="handleSelectedMonthChange"
      />

      <div class="date-picker__dropdown-footer">
        <Button
          class="date-picker__button"
          :type="EButtonType.ghostPrimary"
          :size="ESize.small"
          :title="tt('shared.today')"
          @click="handleCurrentDateSet"
        />
      </div>
    </DropdownList>
  </ControlWrapper>
</template>

<script lang="ts">

import {
  PropType,
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  toRefs,
} from 'vue';

import SelectorVerticalSvg from '@/assets/svg/16x16/selector-vertical.svg';
import tt from '@/i18n/utils/translateText';
import { capitalize } from '@/utils';
import { TDateAppFormat } from '@/types';

import Button from '../Button/index.vue';
import ControlWrapper from '../ControlWrapper/index.vue';
import DropdownList from '../DropdownList/index.vue';
import Select from '../Select/index.vue';
import { EButtonType, ESize } from '../types';
import DatePickerInput from './components/DatePickerInput/index.vue';
import DatePickerMonthFeed from './components/DatePickerMonthFeed/index.vue';
import {
  DEFAULT_MAX_DATE,
  DEFAULT_MIN_DATE,
  EDatePickerValueFormat,
} from '../types/constants';
import { useInteractWithDatePicker } from './application/useInteractWithDatePicker';
import { useChangeDropdownVisibility } from './application/useChangeDropdownVisibility';
import { useChangeDateState } from './application/useChangeDateState';

export default defineComponent({
  name: 'DatePicker',
  components: {
    Button,
    ControlWrapper,
    DatePickerInput,
    DatePickerMonthFeed,
    DropdownList,
    Select,
    SelectorVerticalSvg,
  },
  props: {
    value: {
      type: [String, Object] as PropType<string | TDateAppFormat>,
      required: true,
    },
    /**
     * Формат передаваемого/возвращаемого значения даты (value). Если формат не указан, в качестве параметра value ожидается объект TDateAppFormat, возвращается так же объект TDateAppFormat.
     *
     * Указываем формат, когда хотим в качестве параметра value передать строку и соответственно при изменении даты в компоненте, значение будет отдаваться строкой в выбранном формате.
     * */
    valueFormat: {
      type: [String, null] as PropType<EDatePickerValueFormat | null>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    /** Формат 'YYYY-MM-DD' */
    minDate: {
      type: String,
      default: DEFAULT_MIN_DATE,
    },
    /** Формат 'YYYY-MM-DD' */
    maxDate: {
      type: String,
      default: DEFAULT_MAX_DATE,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
  },
  emits: [],
  setup(props) {
    const {
      value,
      valueFormat,
      minDate,
      maxDate,
    } = toRefs(props);

    const dropdownRef = ref<InstanceType<typeof DropdownList> | null>(null);
    const wrapperRef = ref<InstanceType<typeof ControlWrapper> | null>(null);
    const datePickerInputRef = ref<InstanceType<typeof DatePickerInput> | null>(null);
    const datePickerMonthFeedRef = ref<InstanceType<typeof DatePickerMonthFeed> | null>(null);

    const wrapperHtmlRef = computed(() => (wrapperRef.value
      ? wrapperRef.value.wrapper
      : null));

    const {
      isDropdownVisible,

      handleDatePickerInputFocus,
      handleDatePickerInputBlur,
      handleDropdownBlur,
      handleSelectFocus,
      handleSelectBlur,
      handleKeydown,
      setDropdownFocus,
    } = useChangeDropdownVisibility({
      dropdownRef,
      datePickerInputRef,
    });

    const {
      selectedDateState,
      selectedDateValue,

      changeDateState,
    } = useChangeDateState(value, valueFormat);

    const {
      yearsOptions,
      monthsOptions,
      monthSelectValue,
      yearSelectValue,

      handleCurrentDateSet,
      handleSelectedMonthChange,
      handleDaySelect,
      handleMonthSelect,
      handleYearSelect,
    } = useInteractWithDatePicker({
      minDate,
      maxDate,
      datePickerMonthFeedRef,
      setDropdownFocus,
      changeDateState,
    });

    onMounted(() => {
      document.addEventListener('keydown', handleKeydown);
    });

    onUnmounted(() => {
      document.removeEventListener('keydown', handleKeydown);
    });

    return {
      dropdownRef,
      wrapperRef,
      wrapperHtmlRef,
      datePickerInputRef,
      datePickerMonthFeedRef,
      isDropdownVisible,
      yearsOptions,
      monthsOptions,
      yearSelectValue,
      monthSelectValue,
      selectedDateState,
      selectedDateValue,
      EButtonType,
      ESize,

      tt,
      capitalize,
      handleDatePickerInputFocus,
      handleDatePickerInputBlur,
      handleDropdownBlur,
      handleSelectFocus,
      handleSelectBlur,
      handleCurrentDateSet,
      setDropdownFocus,
      handleSelectedMonthChange,
      handleDaySelect,
      handleMonthSelect,
      handleYearSelect,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
