import { httpMethods } from '@/api';
import { sendApiRequest } from '@/api/sendApiRequest';
import {
  TExportToFileResponse,
  TFileUploadInfo,
  TImportInfoResponse,
  TPaginationForRequest,
  TTableColumns,
} from '@/types';
import { TFilterItemDTO } from '@/components/Filter/types';

import {
  TAddMatrixRequest,
  TImportMatrixReportRequest,
  TImportMatrixRowsRequest,
  TMatricesResponse,
  TMatrixFormSchemaResponse,
  TMatrixRowsRequest,
  TMatrixRowsResponse,
} from '../domain/types';

/** Получение данных о матрицах и типах их полей */
export const getMatrices = () => sendApiRequest<TMatricesResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/matrices',
});

/** Получение настроек колонок для отображения таблицы матриц */
export const getMatricesColumnsConfig = (matrixId: string) => sendApiRequest<TTableColumns>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/matrices/${matrixId}/matrix_rows/columns`,
});

/** Получение значений для строк таблицы матрицы */
export const getMatrixRows = (
  {
    filterId,
    matrixId,
    currentPage,
    pageItems,
    sort,
    sortBy,
  } = {
    pageItems: null,
    currentPage: null,
  } as TPaginationForRequest & TMatrixRowsRequest,
) => sendApiRequest<TMatrixRowsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/matrices/${matrixId}/matrix_rows`,
  params: {
    items: pageItems,
    page: currentPage,
    filterId,
    sort,
    sortBy,
  },
});

/** Получение конфигурации для формы создания матрицы */
export const getMatrixFormSchema = (matrixId: string) => sendApiRequest<TMatrixFormSchemaResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/matrices/${matrixId}/matrix_rows/form_schema`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

/** Добавление новой строки в матрицу */
export const createMatrixRow = (matrixId: string, payload: TAddMatrixRequest) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/matrices/${matrixId}/matrix_rows`,
  payload,
});

/** Редактирование строки матрицы */
export const editMatrixRow = (matrixId: string, payload: TAddMatrixRequest, id: string) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/matrices/${matrixId}/matrix_rows/${id}`,
  payload,
});

/** Удаление строки матрицы */
export const deleteMatrixRow = (matrixId: string, id: string) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/matrices/${matrixId}/matrix_rows/${id}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

/** Импорт строк матрицы из Excel */
export const importMatrixFromExcel = (
  { file }: { file: TFileUploadInfo },
  { matrixId, mode }: TImportMatrixRowsRequest) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/matrices/${matrixId}/imports`,
  payload: {
    file,
    mode,
  },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getMatrixImportInfo = (
  matrixId: string, importId: string) => sendApiRequest<TImportInfoResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/matrices/${matrixId}/imports/${importId}`,
});

/** Применить фильтры к строкам матрицы */
export const applyMatrixRowsFilters = (
  matrixId: string,
  payload: { filters: TFilterItemDTO[] },
) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/matrices/${matrixId}/matrix_rows/filters`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

/** Генерация отчета по матрице */
export const exportMatrixReport = (
  matrixId: string,
  payload: TImportMatrixReportRequest,
) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/matrices/${matrixId}/report_exports`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

/** Сгенерировать файл для выгрузки строк матрицы */
export const exportMatrixRowsToExcel = (matrixId: string) => sendApiRequest<TExportToFileResponse>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/matrices/${matrixId}/exports`,
});
