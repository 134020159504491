import {
  computed,
  Ref,
  ref,
  watch,
} from 'vue';

import {
  getDaysFromDate,
  getMonthsFromDate,
  getYearsFromDate,
  isDayjsGuard,
  isValidDate,
} from '@/utils/dateUtils';
import { EDatePickerValueFormat } from '@/ui/types/constants';
import { TDateAppFormat } from '@/types';

import {
  COUNT_DAY_DIGITS,
  COUNT_MONTH_DIGITS,
  COUNT_YEAR_DIGITS,
  DEFAULT_DATE_STATE,
} from '../domain/constants';
import { TChangeDateStateArguments, TDateSections } from '../domain/types';

export const useChangeDateState = (
  value: Ref<string | TDateAppFormat>,
  valueFormat: Ref<EDatePickerValueFormat | null>,
) => {
  const selectedDateState = ref<TDateSections>({ ...DEFAULT_DATE_STATE });

  const selectedDateValue = computed(() => {
    const { day, month, year } = selectedDateState.value;

    if (day && month && year) {
      return `${year}-${month}-${day}`;
    }

    return null;
  });

  /**
   * @param date - объект, содержащий фрагменты даты (день/месяц/год).
   * @param isNeedFormatDate - флаг, указывающий на необходимость форматирования фрагментов даты (добавление недостающих нулей перед днем/месяцем/годом).
   */
  const changeDateState = ({
    date,
    isNeedFormatDate = false,
  }: TChangeDateStateArguments) => {
    if (isNeedFormatDate) {
      selectedDateState.value = {
        day: date.day ? `0${date.day}`.slice(-COUNT_DAY_DIGITS) : null,
        month: date.month ? `0${date.month}`.slice(-COUNT_MONTH_DIGITS) : null,
        year: date.year ? `0000${date.year}`.slice(-COUNT_YEAR_DIGITS) : null,
      };
    } else {
      selectedDateState.value = { ...date };
    }
  };

  watch(value, () => {
    if (isDayjsGuard(value.value) || (valueFormat.value && isValidDate(value.value, valueFormat.value, true))) {
      const date: TDateSections = {
        day: `${getDaysFromDate(value.value)}`,
        month: `${getMonthsFromDate(value.value) + 1}`,
        year: `${getYearsFromDate(value.value)}`,
      };

      changeDateState({
        date,
        isNeedFormatDate: true,
      });
    } else {
      changeDateState({ date: DEFAULT_DATE_STATE });
    }
  }, { immediate: true });

  return {
    selectedDateState,
    selectedDateValue,

    changeDateState,
  };
};
