export const getCSSProperty = (
  {
    key,
    value,
    unit,
  }: {
    key: 'left' | 'height' | 'top' | 'width' | 'min-width' | 'max-width',
    value: number | string,
    unit: string,
  }) => (key && value && unit ? `${key}: ${value}${unit};` : '');
