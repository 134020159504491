<template>
  <div class="quick-filter">
    <template
      v-for="(filter) in options"
      :key="filter.value"
    >
      <div
        class="quick-filter__item"
        :class="[
          { 'quick-filter__item-checked': checked[filter.value] },
          { 'quick-filter__item-small': size === ESize.small }
        ]"
        @click="handleFilterChange(filter.value)"
      >
        <component
          :is="filter.iconLeft"
          v-if="filter.iconLeft"
          class="quick-filter__icon-left"
          :class="{ 'quick-filter__icon-left-small': size === ESize.small }"
        />
        <span>{{ filter.label }}</span>
        <component
          :is="filter.iconRight"
          v-if="filter.iconRight"
          class="quick-filter__icon-right"
          :class="{ 'quick-filter__icon-right-small': size === ESize.small }"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">

import {
  defineComponent,
  onMounted,
  PropType,
  ref,
} from 'vue';

import {
  ESize,
  TQuickFilterItem,
  TQuickFilterSize,
} from '@/ui/types';

export default defineComponent({
  name: 'QuickFilter',
  props: {
    options: {
      type: Object as PropType<TQuickFilterItem[]>,
      required: true,
    },
    size: {
      type: String as PropType<TQuickFilterSize>,
      default: ESize.medium,
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    const checked = ref<Record<string, boolean>>({});

    onMounted(() => {
      checked.value = props.options.reduce((acc: Record<string, boolean>, item: TQuickFilterItem) => {
        if (item.checked) {
          acc[item.value] = true;
        }

        return acc;
      }, {});
    });

    const handleFilterChange = (filterValue: string) => {
      checked.value[filterValue] = !checked.value[filterValue];
      const checkedValue = props.options
        .filter((item: TQuickFilterItem) => checked.value[item.value])
        .map((item: TQuickFilterItem) => ({
          ...item,
          checked: true,
        }));

      emit('select', checkedValue);
    };

    return {
      ESize,
      checked,

      handleFilterChange,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
