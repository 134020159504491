<template>
  <ControlWrapper
    ref="wrapperRef"
    class="select"
    tabindex="0"
    :disabled="disabled"
    :isInvalid="isInvalid"
    :size="size"
    :hasBorder="hasBorder"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    @mousedown.prevent.stop="handleControlWrapperMousedown"
  >
    <slot name="addonBefore" />
    <Input
      ref="inputRef"
      data-toggle="dropdown"
      aria-haspopup="true"
      :aria-expanded="isDropdownVisible"
      :value="selectedValue"
      :size="size"
      :placeholder="placeholder"
      :hasBorder="false"
      :readonly="readonly"
      :class="inputClassName"
      :disabled="disabled"
      @focus="handleInputFocus"
      @blur="handleInputBlur"
      @change="onChange"
      @input="onInput"
      @keydown="onInputKeydown"
    />
    <slot name="addonAfter" />
    <DropdownList
      ref="dropdownRef"
      :className="dropdownComputedClassName"
      :optionsLength="optionsLength"
      :targetElement="wrapperHtmlRef"
      :visible="isDropdownVisible"
      :dropdownMatchSelectWidth="dropdownMatchSelectWidth"
      :notFoundContent="notFoundContentText"
      :isFixedOnBottomLeft="isOptionsFixedOnLeft"
      :withSearch="withSearch"
      :searchValue="searchValue"
      @keydown="onDropdownKeydown"
      @blur="handleDropdownBlur"
      @search="handleOptionSearch"
      @updateList="$emit('updateList')"
    >
      <template v-if="isDropdownFooterVisible" #footerControlLeft>
        <slot name="footerControlLeft" />
      </template>
      <template v-if="isDropdownFooterVisible" #footerControlRight>
        <slot name="footerControlRight" />
      </template>
      <template #notFoundContent>
        <slot v-if="hasNotFoundContentSlot" name="notFoundContent" />
      </template>
      <template v-if="hasContent">
        <slot v-if="hasSlotOptions" />
        <template v-else>
          <!-- Так как нет отдельных уникальных полей,
           используем уникальную комбинацию полей самого объекта c JSON.stringify -->
          <div
            v-for="optionGroup in optionsToDisplay"
            :key="JSON.stringify(optionGroup)"
            :class="{ 'select__dropdown-group_divided': optionGroup.hasDivider }"
          >
            <p
              v-if="optionGroup.label"
              class="select__dropdown-group-label"
            >
              {{ optionGroup.label }}
            </p>
            <DropdownListItem
              v-for="option in optionGroup.options"
              :key="option.value"
              :class="{ 'select__option-item_focused': option.key === String(focusedOptionIndex) }"
              :value="option.label"
              :selected="isSelectedValue(value, option)"
              :disabled="option.disabled"
              :info="option.info"
              :subTitle="option.subTitle"
              :subInfo="option.subInfo"
              :isFullLabelVisible="isFullLabelVisible"
              @click="onClick({
                value: option.value,
                label: option.label,
              })"
            >
              <template v-if="option.icon" #icon>
                <component
                  :is="option.icon"
                />
              </template>
            </DropdownListItem>
          </div>
        </template>
        <slot name="dropdownRender" />
      </template>
      <div
        v-if="showFixedContent"
        class="select__fixed-content"
      >
        <slot name="fixedContent">
          <div
            class="select__fixed-content-button"
            @click="$emit('fixedContentClick')"
          >
            <PlusSvg class="select__fixed-content-icon" />
            <span>{{ fixedContentText }}</span>
          </div>
        </slot>
      </div>
    </DropdownList>
    <template #suffix>
      <button
        v-if="allowClear"
        class="slotted select__clear-button"
        :class="{ 'select__clear-button_shown': canRemoveValue }"
        @mousedown.stop="onRemoveContent"
      >
        <CancelationSvg viewBox="0 0 16 16" />
      </button>
      <DownSvg
        v-if="showArrow"
        viewBox="0 0 16 16"
        class="slotted select__icon"
        :class="{ 'select__icon-arrow_up': isDropdownVisible }"
        @mousedown.prevent.stop="handleClose"
      />
    </template>
  </ControlWrapper>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  toRef,
  watch,
} from 'vue';

import {
  ESize,
  TSelectOption,
  TSelectValue,
  TSelectOptions,
  TSelectOptionsGroup,
} from '@/ui/types';
import { useHasSlot } from '@/composables/useHasSlot';
import tt from '@/i18n/utils/translateText';
import DownSvg from '@/assets/svg/16x16/chevron-down.svg';
import CancelationSvg from '@/assets/svg/16x16/cancelation.svg';
import { EKeyboardKey } from '@/constants';
import PlusSvg from '@/assets/svg/16x16/plus.svg';

import { getGroupedOptions } from '../utils/getGroupedOptions';
import DropdownList from '../DropdownList/index.vue';
import DropdownListItem from '../DropdownList/components/DropdownListItem/index.vue';
import Input from '../Input/index.vue';
import ControlWrapper from '../ControlWrapper/index.vue';
import { getSelectedValue } from './utils/getSelectedValue';
import { isSelectedValue } from './utils/isSelectedValue';
import { isSelectOptionsGroupsGuard } from './utils/guards';

export default defineComponent({
  name: 'Select',
  components: {
    Input,
    ControlWrapper,
    DropdownList,
    DropdownListItem,
    DownSvg,
    CancelationSvg,
    PlusSvg,
  },
  props: {
    value: {
      type: [String, Number, Object, null] as PropType<TSelectValue | null>,
      default: null,
    },
    // Поддерживаем 2 интерфейса. Обычным списком И группами вида
    // { label: string, options: TSelectOption[] }
    options: {
      type: Array as PropType<TSelectOptions>,
      default: () => [],
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: tt('shared.placeholder.typeSelect'),
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    /** Пропс на отображение текста с переносом на следующую строку */
    isFullLabelVisible: {
      type: Boolean,
      default: false,
    },
    dropdownClassName: {
      type: String,
      default: '',
    },
    dropdownMatchSelectWidth: {
      type: [Number, Boolean] as PropType<number | boolean>,
      default: true,
    },
    notFoundContent: {
      type: String,
      default: '',
    },
    labelInValue: {
      type: Boolean,
      default: false,
    },
    trackBy: {
      type: String,
      default: 'value',
    },
    showArrow: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
    showFixedContent: {
      type: Boolean,
      default: false,
    },
    fixedContentText: {
      type: String,
      default: tt('shared.action.add'),
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    withAutocomplete: {
      type: Boolean,
      default: false,
    },
    /** зафиксировать список options у левого края Select'а */
    isOptionsFixedOnLeft: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:value',
    'change',
    'blur',
    'focus',
    'select',
    'search',
    'updateList',
    'fixedContentClick',
  ],
  setup(props, { emit, slots, expose }) {
    const inputRef = ref<typeof Input | null>(null);
    const dropdownRef = ref<typeof DropdownList | null>(null);
    const wrapperRef = ref<typeof ControlWrapper | null>(null);
    const isDropdownVisible = ref<boolean>(false);
    const optionsLength = ref<number | null>(null);
    const isInputFocus = ref<boolean>(false);
    const isDropdownFocus = ref<boolean>(false);
    const searchValue = ref<string>('');
    const focusedOptionIndex = ref<number | null>(null);

    const hasNotFoundContentSlot = useHasSlot(slots, 'notFoundContent');
    const hasDropdownRenderSlot = useHasSlot(slots, 'dropdownRender');
    const hasSlotOptions = useHasSlot(slots, 'default');

    const wrapperHtmlRef = computed(() => (wrapperRef.value
      ? (wrapperRef.value as typeof ControlWrapper).wrapper
      : null));

    const hasContent = computed(() => !!optionsLength.value);
    const notFoundContentText = computed(() => props.notFoundContent || tt('shared.emptyList'));
    const canRemoveValue = computed(() => props.allowClear && !!props.value);
    const inputClassName = computed(() => ({ select__input_readonly: props.readonly }));
    const isDropdownFooterVisible = computed(() => slots.footerControlLeft || slots.footerControlRight);
    const groupedOptions = computed(() => getGroupedOptions(props.options) as TSelectOptionsGroup[]);

    const dropdownComputedClassName = computed(() => {
      let resultClassName = props.dropdownClassName;

      if (props.showFixedContent) {
        resultClassName += ' select__dropdown_fixed-content';
      }

      return resultClassName;
    });

    // Вычисляем опции единым списком, если они были в сгруппированном виде, для упрощения поиска selectedValue
    const listedOptions = computed<TSelectOption[]>(() => (isSelectOptionsGroupsGuard(props.options)
      ? props.options.map((option: TSelectOptionsGroup) => option.options).flat()
      : props.options));

    const selectedValue = computed(() => getSelectedValue(toRef(props, 'value'), listedOptions));

    // Фильтруем группы опций с условием введенного поиска
    const optionsToDisplay = computed<TSelectOptionsGroup[]>(() => {
      if (!searchValue.value) return groupedOptions.value;
      // используем reduce, чтобы накопить только те группы, поисковые совпадения по опциям которых, пригодны к выводу
      return groupedOptions.value.reduce((result: TSelectOptionsGroup[], group: TSelectOptionsGroup) => {
        const groupOptions = group.options?.filter((opt: TSelectOption) => {
          const sourceText = String(opt.label).toUpperCase();
          return sourceText.indexOf(searchValue.value.toUpperCase()) > -1;
        });
        // если после фильтрафии опций в группе не осталось - не добавляем эту группу в итоговый вывод
        return !groupOptions?.length ? result : result.concat([{
          ...group,
          options: groupOptions,
        }]);
      }, []);
    });

    const onClick = (value: TSelectOption) => {
      onUpdateValue(value);
      isDropdownVisible.value = false;
    };

    /*
      * При взаимодействии пользователя с Селектом - есть 3 основных состояния:
      * 1 - Дефолное состояние (закрыт дропдаун, нет фокуса на элементах)
      * 2 - Открыт дропдаун и фокус на нем же
      * 3 - Открыт дропдаун, но фокус стоит на инпуте (если !readonly)
    */
    const openDropdown = () => {
      isDropdownVisible.value = true;

      nextTick().then(() => {
        isDropdownFocus.value = true;
        // Добавляем фокус дропдауну (или полю поиска), даже если в дропдауне нет контента т.к. мы все
        // равно отображаем текст и хотим закрывать дропдаун при blur`e
        if (props.withSearch) {
          dropdownRef.value?.focusSearch();
        } else {
          dropdownRef.value?.focus({ preventScroll: true });
        }
      });
    };

    const handleControlWrapperMousedown = () => {
      if (props.disabled) return;
      if (!isDropdownVisible.value) { // Открываем дропдун (первый клик по селекту)
        openDropdown();

        return;
      }
      if (props.readonly) { // Закрываем дропдаун
        isDropdownVisible.value = false;
      }

      // Добавляем фокус инпуту
      inputRef.value?.focus();
    };

    const handleDropdownBlur = () => {
      isDropdownFocus.value = false;
      if (isDropdownVisible.value && !isInputFocus.value) {
        isDropdownVisible.value = false;
      }
    };

    const handleInputFocus = () => {
      isInputFocus.value = true;
      if (!props.readonly && !isDropdownVisible.value) {
        isDropdownVisible.value = true;
      }
    };

    const handleInputBlur = () => {
      // Закрывем дропдаун только в том случае, если он был уже открыт, readonly, фокус стоял на инпуте, но кликнули по другому элементу
      if (!isDropdownFocus.value && isDropdownVisible.value && !props.readonly && isInputFocus.value) {
        isDropdownVisible.value = false;
      }
      isInputFocus.value = false;
    };

    const handleClose = () => {
      if (props.disabled) return;
      if (!isDropdownVisible.value) {
        openDropdown();
      } else {
        isDropdownVisible.value = false;
      }
    };

    const onRemoveContent = () => {
      emit('update:value', null);
      emit('change', null);
    };

    const onInput = (event: Event) => {
      const targetValue = (event.target as HTMLInputElement).value;
      emit('update:value', targetValue);
    };

    const onChange = (event: Event) => {
      const targetValue = (event.target as HTMLInputElement).value;
      emit('update:value', targetValue);
      emit('change', targetValue);
    };

    const onInputKeydown = (event: KeyboardEvent) => {
      if (!hasContent.value) return;
      if (event.key === EKeyboardKey.arrowDown) {
        event.preventDefault();
        openDropdown();
        focusedOptionIndex.value = 0;
      }
      if (event.key === EKeyboardKey.enter) {
        emit('change', props.value);
      }
    };

    const onDropdownKeydown = (event: KeyboardEvent) => {
      event.preventDefault();
      if (!hasContent.value) return;
      if (event.key === EKeyboardKey.arrowDown) {
        // если фокуса не было, устанавливаем его на первый элемент
        if (focusedOptionIndex.value === null) {
          focusedOptionIndex.value = 0;
        } else if (focusedOptionIndex.value < listedOptions.value.length - 1) {
          focusedOptionIndex.value += 1;
        }
      }
      if (event.key === EKeyboardKey.arrowUp) {
        // если фокус на 1 элементе или его вообще не было, то ставим на последний элемент списка
        if (focusedOptionIndex.value === 0 || focusedOptionIndex.value === null) {
          focusedOptionIndex.value = listedOptions.value.length - 1;
        } else if (focusedOptionIndex.value > 0) {
          focusedOptionIndex.value -= 1;
        }
      }
      if (event.key === EKeyboardKey.enter) {
        if (focusedOptionIndex.value === null) return;
        const focusedOption = listedOptions.value[focusedOptionIndex.value];
        if (!focusedOption || focusedOption.disabled) return;
        onUpdateValue(focusedOption);
      }
    };

    const autofocus = () => wrapperRef.value?.focus();

    expose({ autofocus });

    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === EKeyboardKey.tab || event.key === EKeyboardKey.escape || event.key === EKeyboardKey.enter) {
        if (isDropdownVisible.value) {
          isDropdownVisible.value = false;
          inputRef.value?.blur(event);
        }
      }
    };

    const onUpdateValue = (value: TSelectOption) => {
      emit('update:value', value.value);
      if (props.labelInValue) {
        emit('select', value);
        emit('change', value);
      } else {
        emit('select', value[props.trackBy]);
        emit('change', value[props.trackBy]);
      }
    };

    const handleOptionSearch = (value: string) => {
      if (props.withAutocomplete) {
        emit('search', value);
      } else {
        searchValue.value = value;
      }
    };

    watch(isDropdownVisible, (isVisible: boolean) => {
      // Обнуляем поле поиска по закрытию Dropdown
      if (!isVisible && searchValue.value) {
        searchValue.value = '';
      }
    });

    watch(optionsToDisplay, () => {
      // для динамически подгружаемых options
      optionsLength.value = optionsToDisplay.value?.map((option: TSelectOptionsGroup) => option.options).flat().length;
    }, {
      deep: true,
      immediate: true,
    });

    watch(isDropdownVisible, () => {
      if (isDropdownVisible.value) {
        // nextTick тут добавлен для корректной отработки фокуса при переключении с другого элемента с раскрытым
        // выпадающем списком (например Datepicker или Multiselect). Иначе focus тригерится раньше тригера blur
        // предыдущего активного элемента.
        nextTick(() => {
          emit('focus');
        });
      } else {
        emit('blur');
      }
    });

    onMounted(() => {
      document.addEventListener('keydown', handleKeydown);
    });

    onUnmounted(() => {
      document.removeEventListener('keydown', handleKeydown);
    });

    return {
      selectedValue,
      optionsToDisplay,
      searchValue,
      inputRef,
      dropdownRef,
      wrapperRef,
      wrapperHtmlRef,
      optionsLength,
      notFoundContentText,
      isDropdownVisible,
      isSelectedValue,
      hasNotFoundContentSlot,
      hasDropdownRenderSlot,
      hasContent,
      hasSlotOptions,
      inputClassName,
      canRemoveValue,
      dropdownComputedClassName,
      isDropdownFooterVisible,
      focusedOptionIndex,

      onClick,
      handleClose,
      handleControlWrapperMousedown,
      onChange,
      onInput,
      onDropdownKeydown,
      onInputKeydown,
      onRemoveContent,
      handleDropdownBlur,
      handleInputFocus,
      handleInputBlur,
      handleOptionSearch,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
