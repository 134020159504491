import {
  Ref,
  computed,
  ref,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import {
  getDaysFromDate,
  getMonthsFromDate,
  getNowISO,
  getYearsFromDate,
} from '@/utils/dateUtils';
import { EMonth } from '@/ui/types/constants';

import DatePickerMonthFeed from '../components/DatePickerMonthFeed/index.vue';
import { TChangeDateStateArguments, TDateSections } from '../domain/types';

type TUseInteractWithDatePicker = {
  minDate: Ref<string>,
  maxDate: Ref<string>,
  datePickerMonthFeedRef: Ref<InstanceType<typeof DatePickerMonthFeed> | null>,
  setDropdownFocus: () => void,
  changeDateState: ({ date, isNeedFormatDate }: TChangeDateStateArguments) => void,
};

export const useInteractWithDatePicker = (
  {
    minDate,
    maxDate,
    datePickerMonthFeedRef,
    setDropdownFocus,
    changeDateState,
  }: TUseInteractWithDatePicker,
) => {
  const currentDate = getNowISO();

  const monthSelectValue = ref(getMonthsFromDate(currentDate) + 1);
  const yearSelectValue = ref(getYearsFromDate(currentDate));

  const yearsOptions = computed(() => {
    const maxYear = getYearsFromDate(maxDate.value);
    const minYear = getYearsFromDate(minDate.value);

    return Array.from({ length: maxYear - minYear + 1 }).map((_, index: number) => ({
      value: minYear + index,
      label: minYear + index,
    }));
  });

  const monthsOptions = computed(
    () => Object.keys(EMonth).filter((value: string) => Number.isNaN(Number(value))).map(
      (month: string, index: number) => ({
        value: index + 1,
        label: tt(`shared.months.${month as keyof typeof EMonth}`),
      })),
  );

  const handleCurrentDateSet = () => {
    const day = getDaysFromDate(currentDate);
    // Увеличиваем на 1, так как функция возвращает месяц в диапазоне от 0 до 11
    const month = getMonthsFromDate(currentDate) + 1;
    const year = getYearsFromDate(currentDate);

    const date: TDateSections = {
      day: `${day}`,
      month: `${month}`,
      year: `${year}`,
    };

    changeDateState({
      date,
      isNeedFormatDate: true,
    });

    handleSelectedMonthChange(month, year);

    datePickerMonthFeedRef.value?.changeMonthFeedState(month, year);
  };

  const handleSelectedMonthChange = (month: EMonth, year: number) => {
    monthSelectValue.value = month;
    yearSelectValue.value = year;
  };

  const handleMonthSelect = (month: EMonth) => {
    setDropdownFocus();

    monthSelectValue.value = month;

    datePickerMonthFeedRef.value?.changeMonthFeedState(month, yearSelectValue.value);
  };

  const handleYearSelect = (year: number) => {
    setDropdownFocus();

    yearSelectValue.value = year;

    datePickerMonthFeedRef.value?.changeMonthFeedState(monthSelectValue.value, year);
  };

  const handleDaySelect = (date: TDateSections) => {
    changeDateState({
      date,
      isNeedFormatDate: true,
    });
  };

  return {
    yearsOptions,
    monthsOptions,
    monthSelectValue,
    yearSelectValue,

    handleCurrentDateSet,
    handleSelectedMonthChange,
    handleDaySelect,
    handleMonthSelect,
    handleYearSelect,
  };
};
